
export default {
  name: 'MoleculeContentBlocks',
  props: {
    contentBlocks: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    presentationData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    cdnBase() {
      return this.$store.getters['base/cdnBase']
    }
  },
  methods: {
    getTitleTranslation(contentBlock) {
      if (contentBlock.translations && contentBlock.translations.length > 0) {
        const foundTranslation = contentBlock.translations.find((t) => t.language === this.locale)
        if (foundTranslation) {
          return foundTranslation.content_title
        }
      }
      return contentBlock.content_title
    }
  }
}
