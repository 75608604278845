
export default {
  name: 'MoleculeContentBlockEmbed',
  props: {
    contentBlock: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
