
export default {
  name: 'MoleculeContentBlockText',
  props: {
    contentBlock: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    }
  },
  watch: {},
  methods: {
    getTitleTranslation() {
      if (this.contentBlock.translations && this.contentBlock.translations.length > 0) {
        const foundTranslation = this.contentBlock.translations.find(
          (t) => t.language === this.locale
        )
        if (foundTranslation) {
          return foundTranslation.content_title
        }
      }
      return this.contentBlock.content_title
    },
    getContentTranslation() {
      if (this.contentBlock.translations && this.contentBlock.translations.length > 0) {
        const foundTranslation = this.contentBlock.translations.find(
          (t) => t.language === this.locale
        )
        if (foundTranslation) {
          return foundTranslation.content_description
        }
      }
      return this.contentBlock.content_description
    }
  }
}
