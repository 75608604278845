
export default {
  name: 'MoleculeContentBlockVideo',
  props: {
    contentBlock: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    presentationData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    autoPlay: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    cdnBase() {
      return this.$store.getters['base/cdnBase']
    },
    clientName() {
      return this.$config.CLIENT
    },
    presentationDataUuid() {
      return this.presentationData?.uuid
    }
  },
  mounted() {
    this.$refs.video && this.autoPlay && this.$refs.video.play()
  },
  methods: {}
}
